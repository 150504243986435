<div *ngIf="aemLabelAuthoring">
  <!-- <div aria-live="assertive" class="visually-hidden">
    <span role="presentation" style="position:absolute;left:-9999px">Create New Customer Screen opened</span>
  </div> -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb" >
      <li class="breadcrumb-item" role="link"><a href="/">Home</a></li>
      <i class="icon-icon-ui-right-arrow breadcrumb-icon"></i>
      <li class="breadcrumb-item mid" role="link" (keypress)="moveTab.emit()" (click)="moveTab.emit()" tabindex="0"><a>My Customer</a></li>
      <i class="icon-icon-ui-right-arrow breadcrumb-icon"></i>
      <li class="breadcrumb-item active" aria-current="page">Create New Customer</li>
    </ol>
  </nav>
  <div class="row"><div class="col-6"> <h3 class="createNewCus headingClass" id="customerCreate">{{aemLabelAuthoring.pageHeader.pageHeaderLabel}}</h3>
  </div>
  <div class="col-6">
    <span class="accLabel1">Fields marked with <span class="star">*</span> are mandatory</span></div>
  <form [formGroup]="createCustomerForm" id="createCustomer">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="label required-field" for="customerName">{{aemLabelAuthoring.form.customername}}</label>
              <app-cui-textfield [model]="customerNameModel" [parentFormGroup]="createCustomerForm">
              </app-cui-textfield>
            </div>
          </div>
        </div>
        <div style="padding-bottom: 30px;"></div>
        <div class="row">
          <div class="form-group col-3">
            <label class="label required-field" for="type">{{aemLabelAuthoring.form.customerTypeHead}}</label>
            <app-cui-searchable-dropdown name="customerType" [model]="customerTypeDropDownModel" id="type"
              [labelName]="'customerTypeDropDown'" [placeholder]="'Select customer type'" formControlName="customerType"
              [isRequired]=true [items]=customerTypes automationType="dropdown" automationId="drpdwnCustomerType" (click)="touchCustomerType()"
              [noAutoComplete]=false id="customerType" class="customer-dropDown" (focusout)="touchCustomerType()"
              (ngModelChange)="cutomerTypeChange($event)" required>
            </app-cui-searchable-dropdown>
            <span role="alert" *ngIf="showAlertCustomerType
          && createCustomerForm?.controls?.customerType?.invalid" class="help-block errorMsg"><img class="error-icon"
            src="../../../assets/images/icon-error.svg" />Please enter customer type
        </span>
          </div>
          <div class="form-group col-3">
            <label class="label required-field" for="channel">{{aemLabelAuthoring.form.customerChannelHead}}</label>
            <app-cui-searchable-dropdown name="customerChannel" [model]="customerChannelDropDownModel"
              [labelName]="'customerChannelDropDown'" [placeholder]="'Select customer channel'" id="channel"
              formControlName="customerChannel" [isRequired]=true [items]=customerChannels automationType="dropdown" (click)="touchCustomerChannel()"
              automationId="drpdwnCustomerChannel" [noAutoComplete]=false id="customerChannel" class="customer-dropDown" (focusout)="touchCustomerChannel()"
              required>
            </app-cui-searchable-dropdown>
            <span role="alert" *ngIf="showAlertCustomerChannel
          && createCustomerForm?.controls?.customerChannel?.invalid" class="help-block errorMsg"><img class="error-icon"
            src="../../../assets/images/icon-error.svg" />Please enter customer channel
        </span>
          </div>
          <div class="form-group col-3">
            <label class="label required-field" for="primaryCIDN">{{aemLabelAuthoring.form.primaryCidn}}</label>
            <app-cui-textfield [model]="primaryCIDNModel" [parentFormGroup]="createCustomerForm"
             onkeypress="return /[0-9]/.test(event.key)" onpaste="return false">
            </app-cui-textfield>
          </div>
          <div class="form-group col-3">
            <label class="label required-field" for="status"> Status </label>
            <div class="inpt-type2-cls">
              <app-cui-searchable-dropdown #status name="businessTypes" [formControlName]="'status'"
                [model]="statusDropDownModel" [labelName]="'statusDropdown'" [placeholder]="'Select Status'"
                [isRequired]=true [items]=statuses automationType="dropdown" automationId="drpdwnstatusType"
                [noAutoComplete]=false id="status" class="user-dropDown"
                [ngClass]="{'invalid-form-control': (createCustomerForm.controls.status.invalid && createCustomerForm.controls.status.touched)}"
                required>
              </app-cui-searchable-dropdown>
            </div>
            <div class="row ml-0" aria-live="assertive">
              <span role="alert"
                *ngIf="createCustomerForm.controls.status.invalid && createCustomerForm.controls.status.touched"
                class="help-block errorMsg">
                Please select status
              </span>
            </div>
          </div>
        </div>
        <div style="padding-bottom: 30px;"></div>

      </div>
    </div>
    <div class="row" *ngIf="isLinkedCIDN">
      <table class="table" *ngIf="rowData.length > 0">
        <tr>
          <th *ngFor="let col of profileTableColumn">
            {{col}}
          </th>
        </tr>
        <tr *ngFor="let row of rowData; let i = index" class="cidnRow">
          <td class="customerColumn"> {{row.customerName}} </td>
          <td class="cidnColumn"> {{row.linkedCIDN}} </td>
          <td>
            <button mat-button class="texttype-globalbtn-cls" (click)="removeCustomerDetails(i)"
              attr.aria-label="Remove customer {{row?.customerName}}">
              <img src="../../../../assets/images/icon-delete.svg" alt="Remove Customer" class="rmve-cstmr-cls" />
              {{aemLabelAuthoring.form.remove}}</button>
          </td>
        </tr>
      </table>
    </div>
    <label role="alert" attr.aria-label="Successfully removed" *ngIf="showRemoveAlert" style="position:absolute;left:-9999px">Remove Action peformed</label>
    <ng-container *ngIf="isLinkedCIDN">
      <div class="row">
        <div class="form-group col-3">
          <label class="label required-field" for="lCIDN">{{aemLabelAuthoring.form.linkedCidn}}</label>
          <div class="field-focus" #ref aria-hidden="true" tabindex="-1">
          <app-cui-searchable-dropdown name="linkedCIDN" [model]="linkedCIDNDropDownModel"
            [labelName]="'linkedCIDNDropDown'" [placeholder]="'Primary'" formControlName="linkedCIDN" [isRequired]=false
            [items]=linkedCIDNs automationType="dropdown" automationId="linkedCIDN" [noAutoComplete]=false
             class="customer-dropDown" id="lCIDN" (ngModelChange)="cidnChange()">
          </app-cui-searchable-dropdown>
          </div>
          <div class="row ml-0" aria-live="assertive">
            <ng-container *ngIf="createCustomerForm.controls['linkedCIDN'].value">
              <span role="alert" *ngIf="(!enableSaveCidn)" class="help-block errorMsg">
                Duplicate CIDN
              </span>
            </ng-container>
          </div>
        </div>
      </div>
      <br>
    </ng-container>
    <div class="row">
      <div class="btnWrap" [ngStyle]="{'cursor': enableSaveCidn === true ? 'pointer' : 'not-allowed' }">
        <button class="outline-globalbtn-cls " (click)="openCIDN()">
          <i class="icon-icon-ui-plus roundGreenBorderCls"  *ngIf="enableSaveCidn"></i>
          <img src="../../../assets/images/Icon-Add-btn-disable.svg" *ngIf="!enableSaveCidn"/>
          {{aemLabelAuthoring.form.link}}
        </button>
      </div>
    </div>
    <hr style="margin-top: 60px;margin-bottom: 40px;">
    <h4 class="custContact">{{aemLabelAuthoring.pageHeader.pageHeaderLabel1}}</h4>
    <div class="row">
      <div class="form-group col-3">
        <label class="label required-field" style="margin-left: 3.2px !important;" for="conType">{{aemLabelAuthoring.form.contactType}}</label>
        <app-cui-searchable-dropdown name="contactType" [model]="contactTypeDropDownModel"
          [labelName]="'contactTypeDropDown'" [placeholder]="'Primary'" formControlName="contactType" [isRequired]=true
          [items]=primaryContactType automationType="dropdown" automationId="contactTypeChannel" [noAutoComplete]=false
          id="conType" class="customer-dropDown" (click)="touchContactType()" (focusout)="touchContactType()" required>
        </app-cui-searchable-dropdown>
        <span role="alert" *ngIf="showAlertContactType
          && createCustomerForm?.controls?.contactType?.invalid" class="help-block errorMsg"><img class="error-icon"
            src="../../../assets/images/icon-error.svg" />Please enter contact type
        </span>
      </div>
      <div class="form-group col-3">
        <label class="label required-field">{{aemLabelAuthoring.form.contactName}}</label>
        <app-cui-textfield [model]="contactNameModel" [parentFormGroup]="createCustomerForm">
        </app-cui-textfield>
      </div>
      <div class="form-group col-3">
        <label aria-label="Contact number required" class="label required-field" for="contactNumber" aria-required="true"
          required>{{aemLabelAuthoring.form.contactNumber}}</label>
        <input type="text" class="form-control field-input" maxlength="20" id="contactNumber" name="contactNumber"
          placeholder="Enter contact number" attr.aria-label="document title" formControlName="contactNumber"
          [ngClass]="{'invalid-form-control': (createCustomerForm.controls.contactNumber.touched && createCustomerForm.controls.contactNumber.invalid)}">
        <span role="alert"
          *ngIf="createCustomerForm.controls.contactNumber.touched && createCustomerForm.controls.contactNumber.invalid"
          class="help-block errorMsg">{{aemLabelAuthoring.form.contactNumbervalidationMsg}}</span>
      </div>
      <div class="form-group col-3">
        <label aria-label="Contact email required" class="label required-field" for="contactEmail" aria-required="true"
          required>{{aemLabelAuthoring.form.contactMail}}</label>
        <input type="text" class="form-control field-input" maxlength="255" id="contactEmail" name="contactEmail" (keydown)="emailKeypress($event)"
          placeholder="Enter contact email" attr.aria-label="document title" formControlName="contactEmail"
          [ngClass]="{'invalid-form-control': (createCustomerForm.controls.contactEmail.touched && createCustomerForm.controls.contactEmail.invalid)}">
        <span role="alert"
          *ngIf="createCustomerForm.controls.contactEmail.touched && createCustomerForm.controls.contactEmail.invalid"
          class="help-block errorMsg">{{aemLabelAuthoring.form.contactMailvalidationMsg}}</span>
      </div>
    </div>
    <div style="padding-bottom: 30px;"></div>

    <ng-container *ngIf="isSecondaryContact">
      <div class="row">
        <div class="form-group col-3">
          <label class="label" for="conType">{{aemLabelAuthoring.form.contactType}}</label>
          <div class="field-focus" #cntct tabindex="0">
          <app-cui-searchable-dropdown name="contactTypeSec" [model]="contactTypeDropDownModelSec"
            [labelName]="'contactTypeDropDown'" [placeholder]="'Alternate'" formControlName="contactTypeSec"
            [isRequired]=false [items]=alternateContactType automationType="dropdown" automationId="contactTypeChannel"
            [noAutoComplete]=false id="conType" class="customer-dropDown">
          </app-cui-searchable-dropdown>
        </div>
        </div>
        <div class="form-group col-3">
          <label class="label">{{aemLabelAuthoring.form.contactName}}</label>
          <app-cui-textfield [model]="contactNameModelSec" [parentFormGroup]="createCustomerForm">
          </app-cui-textfield>
        </div>
        <div class="form-group col-3">
          <label class="label" for="contactNumberSec">{{aemLabelAuthoring.form.contactNumber}}</label>
          <input type="text" class="form-control field-input" maxlength="20" id="contactNumberSec"
            name="contactNumberSec" placeholder="Enter contact number" attr.aria-label="document title"
            formControlName="contactNumberSec"
            [ngClass]="{'invalid-form-control': (createCustomerForm.controls.contactNumberSec.touched && createCustomerForm.controls.contactNumberSec.invalid)}">
          <span role="alert"
            *ngIf="createCustomerForm.controls.contactNumberSec.touched && createCustomerForm.controls.contactNumberSec.invalid"
            class="help-block errorMsg">{{aemLabelAuthoring.form.contactNumbervalidationMsg}}</span>
        </div>
        <div class="form-group col-3">
          <label class="label" for="contactEmailSec">{{aemLabelAuthoring.form.contactMail}}</label>
          <input type="text" class="form-control field-input" maxlength="255" id="contactEmailSec"
            name="contactEmailSec" placeholder="Enter contact email" attr.aria-label="document title"
            formControlName="contactEmailSec"
            [ngClass]="{'invalid-form-control': (createCustomerForm.controls.contactEmailSec.touched && createCustomerForm.controls.contactEmailSec.invalid)}">
          <span role="alert"
            *ngIf="createCustomerForm.controls.contactEmailSec.touched && createCustomerForm.controls.contactEmailSec.invalid"
            class="help-block errorMsg">{{aemLabelAuthoring.form.contactMailvalidationMsg}}</span>
        </div>
      </div>
      <div style="padding-bottom: 30px;"></div>
    </ng-container>
    <div class="row">
      <button class="outline-globalbtn-cls" (click)="addContact()" style="margin-left: 12px;">
        <i class="icon-icon-ui-plus roundGreenBorderCls"></i>{{aemLabelAuthoring.form.addContact}}</button>
      <ng-container *ngIf="isSecondaryContact">
        <button class="texttype-globalbtn-cls" (click)="removeSecondaryContact()">
          <i class="icon-icon-ui-cross"></i>{{aemLabelAuthoring.form.removeContact}}</button>
      </ng-container>
    </div>
    <hr>
    <div class="submitCancelBtn">
      <button class="texttype-globalbtn-cls" (click)="cancelBtnClick()" tabindex="0">
        <i class="icon-icon-ui-cross"></i>{{aemLabelAuthoring.form.cancel}}
      </button>
      <button class="primary-globalbtn-cls" (click)="onSubmit()" [disabled]="!createCustomerForm.valid"
        tabindex="0">{{aemLabelAuthoring.form.submit}}</button>
    </div>
  </form>
  <br>
  <br>
</div>
