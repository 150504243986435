<div class="page-bg">
  <div>
    <div class="row">
      <h2 class="col-3" id="customerList"><strong>My customers</strong></h2>

      <div class="col-9 colPrefBtnDiv">
        <div class="colPref">
          <button tabindex="0" class="texttype-globalbtn-cls colPrefBtn" (click)="showPop()"  (keypress)="showPop()">
            <img src="../../../assets/images/icon-column-preferences.svg" alt="column preference icon"
              class="textIcon" />
            Column preferences</button>
          <div class="drop-show" id="colPrefTab"  role="dialog" aria-labelledby="col-pref-dialog" aria-modal="true"  *ngIf="showFilterPop">
            <!-- <label role="alert" style="position:absolute;left:-9999px" aria-live="assertive">Column preference popup opens</label> -->
            <span class="headCls">
              <label class="col-10 labelPref" id="col-pref-dialog">Column preferences</label>
              <button class="closePop" id="close" tabindex="0" aria-label="Close column preferences" (click)="closePopup()"><i
                  class="icon-icon-ui-cross"></i></button>
            </span>
            <div class="popup">
              <div class="decorPop">
                <label class="colmPrefHeaderDiv">Select Columns</label>
                <!--  -->
                <ng-container *ngFor="let a of functionList; index as i">
                  <label  class="labelItm" attr.aria-labelledby="span_{{i}}" for="input_{{i}}" class="mainColPref">
                    <input type="checkbox" name="name_{{i}}" ngDefaultControl [checked]="a.checked" id="input_{{i}}" [(ngModel)]="a.checked" (keypress)="a.checked"
                      (change)="getSelectedValue(a.checked,a.value,a.colId,a.tooltipField,a.filter,a.headerName,a.fullHeaderName,a.customFilter)" />
                    <!-- <span class="checkicon" tabindex="0" role="button"></span> -->
                    <span [attr.aria-label]="a.name" (keydown.space)="toggleCheckbox(a)" (keydown.enter)="toggleCheckbox(a)" id="span_{{i}}" attr.aria-checked="{{a.checked}}" role="checkbox" name="span_{{i}}" ngDefaultControl  [(ngModel)]="a.checked"
                    (keydown.enter)="getSelectedValue(a.checked, a.value, a.colId, a.tooltipField, a.filter, a.headerName, a.fullHeaderName, a.customFilter)"
                    [ngClass]="{'checked': a.checked}" class="checkicon" tabindex="0"></span>
                    {{a.name}}
                  </label>
                </ng-container>
              </div>
            </div>
            <!-- <div class="saveCancelBlock">
              <button tabindex="0" class="closePop accessibilty-last-cross" id="remove" aria-label="Close column preferences" (click)="closePopup()"><i
                class="icon-icon-ui-cross"></i></button>
              <button class="texttype-globalbtn-cls cancel-btn" id="remove" tabindex="0" (click)="closePopup()">
                <i class="icon-icon-ui-cross"></i>Cancel
              </button>
              <button class="outline-globalbtn-cls save-btn" (click)="onSubmit()" disabled>
                <i class="icon-icon-star"></i>Save view
              </button>
            </div>  -->
          </div>
        </div>
        <div>
          <button type="button" class="primary-globalbtn-cls" aria-label="Create New Customer"
            (click)="navigateToCreateUser()">
            <i class="icon-icon-ui-plus roundWhiteBorderCls"></i>
            Create new customer
          </button>
        </div>
      </div>

    </div>

    <div class="col-9 search-grid-container">
      <div class="searchGrid col-9">
        <span>
          <input type="text" class="searchInputfilter" id="text" placeholder="Search customer"
            [(ngModel)]="searchvalue" />
        </span>
        <span class="col-xs-12 col-sm-3 col-md-4 col-lg-3 idsearch">
          <button type="button" class=" primary-globalbtn-cls" aria-label="Search for Customer" (click)="quickSearch()"
            [disabled]="!searchvalue">Search</button>
        </span>
        <span class="col-xs-12 col-sm-3 col-md-3 col-lg-3 idsearch">
          <button type="button" class="texttype-globalbtn-cls" (click)="clearsearch()" [disabled]="!searchvalue">Clear
            search</button>
        </span>
      </div>

    </div>

    <div class="grid-container customer-list-grid" *ngIf="gridConfig && gridInitialized && columnsPresent">
      <app-cui-ag-grid [model]="gridModel" (selectedOnChanged)="onSelectionChanged($event)"
        (buttonClicked)="navigateToCreateUser()" (focusOnTop)="focusOnTop($event)"
        (filtersOnChanged)="onFilterSelected($event)">
      </app-cui-ag-grid>
    </div>
    <div *ngIf="!columnsPresent" class="no-column"> No columns selected </div>
  </div>
</div>
